import { InjectionToken } from '@angular/core';
import { IAscModuleDefinition } from '../i-asc-module-definition';
import { QuickTipClientModuleConfiguration } from './model/quick-tip-client-module-configuration';


export const MODULE_CONFIGURATION = new InjectionToken<QuickTipClientModuleConfiguration>('module configuration');

export const QUICK_TIP_DEFINITION: IAscModuleDefinition = {
   moduleId: 'QUICK_TIP',
   displayName: 'ARI Quick-Tip',
   moduleRoute: {
      path: 'quick-tip',
      loadChildren: () => import('src/modules/quick-tip/quick-tip.module').then(m => m.QuickTipsModule)
   },
   settingsRoute: {
      path: 'settings/quick-tip',
      loadChildren: () => import('src/modules/quick-tip-settings/quick-tip-settings.module').then(m => m.QuickTipSettingsModule)
   }
};