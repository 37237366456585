/* eslint-disable @typescript-eslint/naming-convention */
import { IAscModuleDefinition } from '../i-asc-module-definition';
import { InjectionToken } from '@angular/core';
import { SuSPasswordResetClientModuleConfiguration } from './model/sus-password-reset-client-module-configuration';


export const MODULE_CONFIGURATION = new InjectionToken<SuSPasswordResetClientModuleConfiguration>('module configuration');

export const SUS_PASSWORD_RESET_ROUTE_PATH = {
   SELECT_STUDENT: 'select-student',
   ENTER_CODE: 'enter-code',
   PROCESS_DONE: 'process-done',
   NOTIFICATION: 'notification'
};

export const SUS_PASSWORD_RESET_DEFINITION: IAscModuleDefinition = {
   moduleId: 'SUS_PASSWORD_RESET',
   displayName: 'SuS-Kennwort zurücksetzen',
   moduleRoute: {
      path: 'sus-password-reset',
      loadChildren: () => import('src/modules/sus-password-reset/sus-password-reset.module').then(m => m.SuSPasswordResetModule)
   }
};

export const SUS_PASSWORD_RESET_FUNCTION_EVENT_NAME = {
   SMS_CODE_RESPONSE: 'SmsCodeResponse'
};

export const SUS_PASSWORD_RESET_ACTIVITY_NAME = {
   INITIALIZE: 'Initialize',
   VERIFY_USER_PERMISSIONS: 'VerifyUserPermissions',
   GENERATE_SMS_CODE: 'GenerateSmsCode',
   SEND_SMS_CODE: 'SendSmsCode',
   VERIFY_SMS_CODE: 'VerifySmsCode',
   RESET_PASSWORD: 'ResetPassword',
   COMPLETED: 'Completed'
};

export const SUS_PASSWORD_RESET_ERROR_NAME = {
   ERR_TOO_MANY_PASSWORD_RESET_REQUESTS: 'ERR_TOO_MANY_PASSWORD_RESET_REQUESTS',
   ERR_SENDING_SMS_FAILED: 'ERR_SENDING_SMS_FAILED',
   ERR_UNABLE_TO_SET_PASSWORD: 'ERR_UNABLE_TO_SET_PASSWORD',
   ERR_SMS_CONFIRMATION_FAILED_WITH_RETRY: 'ERR_SMS_CONFIRMATION_FAILED_WITH_RETRY',
   ERR_SMS_CONFIRMATION_FAILED: 'ERR_SMS_CONFIRMATION_FAILED',
   ERR_TIME_OUT: 'ERR_TIME_OUT',
   ERR_NO_PASSWORD_PROVIDED: 'ERR_NO_PASSWORD_PROVIDED',
   ERR_MOBILE_NUMBER_NOT_FOUND: 'ERR_MOBILE_NUMBER_NOT_FOUND',
   /**
    * The entered password does not match with the confirmed password.
    */
   ERR_PASSWORD_MISMATCH: 'ERR_PASSWORD_MISMATCH',
   ERR_PASSWORD_ALREADY_USED: 'ERR_PASSWORD_ALREADY_USED',
   ERR_INTERNAL_ERROR: 'ERR_INTERNAL_ERROR',

   WARN_NO_SUS_FOUND_FOR_TEACHER: 'WARN_NO_SUS_FOUND_FOR_TEACHER'
};
