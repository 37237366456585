export class User {
   public readonly userPrincipalName: string;
   public readonly displayName?: string;
   public readonly id: string;

   constructor(id: string, userPrincipalName: string, displayName?: string) {
      this.id = id;
      this.displayName = displayName;
      this.userPrincipalName = userPrincipalName;
   }
}
