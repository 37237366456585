export class UserGroup {

   public id!: string;
   public displayName!: string;

   public static parse(data: any): UserGroup {
      const userGroup = new UserGroup();
      if (data) {
         userGroup.id = data.id;
         userGroup.displayName = data.displayName;
      }
      return userGroup;
   }
}
