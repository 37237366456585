import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';


@Injectable()
export class NotificationBarService {

   private onMessageChangedValue = new Subject<string | undefined>();
   private messageValue?: string;

   constructor() { }

   // -------------------------------------------------------------- Properties

   public get onMessageChanged(): Observable<string | undefined> {
      return this.onMessageChangedValue;
   }

   public get message(): string | undefined {
      return this.messageValue;
   }

   // ---------------------------------------------------------- Login / logout

   public setMessage(message?: string) {
      if (this.messageValue === message) { return; }

      setTimeout(() => {
         this.messageValue = message;
         this.onMessageChangedValue.next(this.messageValue);
      }, 10);
   }

   public clearMessage() {
      setTimeout(() => {
         this.messageValue = undefined;
         this.onMessageChangedValue.next(this.messageValue);
      }, 10);
   }
}
