import { InjectionToken } from '@angular/core';
import { IAscModuleDefinition } from '../i-asc-module-definition';
import { NotificationsClientModuleConfiguration } from './model/notifications-client-module-configuration';


export const MODULE_CONFIGURATION = new InjectionToken<NotificationsClientModuleConfiguration>('module configuration');

export const NOTIFICATIONS_ROUTE_PATH = {
   ADMIN_LIST_ALL: 'all',
   ADMIN_LIST_APPROVAL: 'approval',
   LIST_MY: 'my',
   EDIT: 'edit',
   TEMPLATES: 'templates',
   NEW: 'new'
};

export const NOTIFICATIONS_DEFINITION: IAscModuleDefinition = {
   moduleId: 'NOTIFICATIONS',
   displayName: 'Meldungen',
   moduleRoute: {
      path: 'notifications',
      loadChildren: () => import('src/modules/notifications/notifications.module').then(m => m.NotificationsModule)
   },
   settingsRoute: {
      path: 'settings/notifications',
      loadChildren: () => import('src/modules/notifications-settings/notifications-settings.module').then(m => m.NotificationsSettingsModule)
   }
};

export const NOTIFICATIONS_ERROR_NAME = {
   ERR_UNKNOWN_USER: 'ERR_UNKNOWN_USER'
};

export const NOTIFICATIONS_MODULE_MODE = {
   READ_ONLY: 'read-only',
   READ_WRITE: 'read-write',
   READ_WRITE_EMERGENCY: 'read-write-emergency',
};
