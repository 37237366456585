import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';

import { AscGenericDialogAction } from './generic-dialog-aciton';

@Component({
   selector: 'asc-generic-dialog',
   templateUrl: './generic-dialog.component.html',
   styleUrls: ['./generic-dialog.component.scss']
})
export class AscGenericDialogComponent {

   public static dialog: MatDialog;

   public static showOkDialogAsync(title: string, message: string, config?: MatDialogConfig<any>): Promise<any> {

      const actions: AscGenericDialogAction[] = [
         new AscGenericDialogAction('OK', AscGenericDialogAction.ACTION_OK)
      ];

      return AscGenericDialogComponent.showDialogAsync(title, message, actions, config);
   }

   public static showOkCancelDialogAsync(title: string, message: string, config?: MatDialogConfig<any>): Promise<any> {

      const actions: AscGenericDialogAction[] = [
         new AscGenericDialogAction('OK', AscGenericDialogAction.ACTION_OK),
         new AscGenericDialogAction('Abbrechen', AscGenericDialogAction.ACTION_CANCEL)
      ];

      return AscGenericDialogComponent.showDialogAsync(title, message, actions, config);
   }

   public static showYesNoDialogAsync(title: string, message: string, config?: MatDialogConfig<any>): Promise<any> {

      const actions: AscGenericDialogAction[] = [
         new AscGenericDialogAction('Ja', AscGenericDialogAction.ACTION_YES),
         new AscGenericDialogAction('Nein', AscGenericDialogAction.ACTION_NO)
      ];

      return AscGenericDialogComponent.showDialogAsync(title, message, actions, config);
   }

   public static showYesNoCancelDialogAsync(title: string, message: string, config?: MatDialogConfig<any>): Promise<any> {

      const actions: AscGenericDialogAction[] = [
         new AscGenericDialogAction('Ja', AscGenericDialogAction.ACTION_YES),
         new AscGenericDialogAction('Nein', AscGenericDialogAction.ACTION_NO),
         new AscGenericDialogAction('Abbrechen', AscGenericDialogAction.ACTION_CANCEL)
      ];

      return AscGenericDialogComponent.showDialogAsync(title, message, actions, config);
   }

   public static showDialogAsync(title: string, message: string, actions: AscGenericDialogAction[], config?: MatDialogConfig<any>): Promise<any> {

      if (!config) { config = {}; }
      if (!config.data) { config.data = {}; }
      config.data.title = title;
      config.data.message = message;
      config.data.actions = actions;

      return firstValueFrom(
         AscGenericDialogComponent.dialog.open(AscGenericDialogComponent, config).afterClosed()
      );
   }

   public title: string;
   public message: string;
   public actions: AscGenericDialogAction[] = [];

   constructor(
      @Inject(MAT_DIALOG_DATA) public data: any
   ) {
      this.title = data.title;
      this.message = data.message;
      this.actions = (data.actions || []);
   }
}
