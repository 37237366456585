export class AscGenericDialogAction {
    public static readonly ACTION_OK = 'OK';
    public static readonly ACTION_CANCEL = 'CANCEL';
    public static readonly ACTION_YES = 'YES';
    public static readonly ACTION_NO = 'NO';

    text: string;
    value: string;

    constructor(text: string, value: string) {
        this.text = text;
        this.value = value;
    }
}
