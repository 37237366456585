import { InjectionToken } from '@angular/core';
import { IAscModuleDefinition } from '../i-asc-module-definition';
import { UserProfilesClientModuleConfiguration } from './model/user-profiles-client-module-configuration';


export const MODULE_CONFIGURATION = new InjectionToken<UserProfilesClientModuleConfiguration>('module configuration');

export const USER_PROFILES_DEFINITION: IAscModuleDefinition = {
   moduleId: 'USER_PROFILES',
   displayName: 'Benutzerprofil',
   moduleRoute: {
      path: 'user-profiles',
      loadChildren: () => import('src/modules/user-profiles/user-profiles.module').then(m => m.UserProfilesModule)
   }
};

export const USER_PROFILES_ROUTE_PATH = {
   EDIT: 'edit'
};