export class AccessToken {
    public token?: string;
    public expiresOn?: Date;


    constructor(token?: string, expiresOn?: Date) {
        this.token = token;
        this.expiresOn = expiresOn;
    }


    public get isValid(): boolean {
        return !!this.expiresOn && this.expiresOn >= new Date();
    }
}