import { IAscModuleDefinition } from '../i-asc-module-definition';


export const DEVELOPMENT_ROUTE_PATH = {
   HOME: 'home'
};

export const DEVELOPMENT_DEFINITION: IAscModuleDefinition = {
   moduleId: 'DEVELOPMENT',
   displayName: 'Entwicklung (Tests)',
   moduleRoute: {
      path: 'development',
      loadChildren: () => import('src/modules/development/development.module').then(m => m.DevelopmentModule)
   }
};

export const DEVELOPMENT_ACTIVITY_NAME = {
};

export const DEVELOPMENT_ERROR_NAME = {
   ERR_UNKNOWN_USER: 'ERR_UNKNOWN_USER',
   ERR_PASSWORD_RESET_DEACTIVATED: 'ERR_PASSWORD_RESET_DEACTIVATED',
   ERR_USER_NOT_ALLOWED: 'ERR_USER_NOT_ALLOWED',
   ERR_TOO_MANY_PASSWORD_RESET_REQUESTS: 'ERR_TOO_MANY_PASSWORD_RESET_REQUESTS',
   ERR_SENDING_SMS_FAILED: 'ERR_SENDING_SMS_FAILED',
   ERR_UNABLE_TO_SET_PASSWORD: 'ERR_UNABLE_TO_SET_PASSWORD',
   ERR_SMS_CONFIRMATION_FAILED_WITH_RETRY: 'ERR_SMS_CONFIRMATION_FAILED_WITH_RETRY',
   ERR_SMS_CONFIRMATION_FAILED: 'ERR_SMS_CONFIRMATION_FAILED',
   ERR_TIME_OUT: 'ERR_TIME_OUT',
   ERR_NO_PASSWORD_PROVIDED: 'ERR_NO_PASSWORD_PROVIDED',
   ERR_MOBILE_NUMBER_NOT_FOUND: 'ERR_MOBILE_NUMBER_NOT_FOUND',
   /**
    * The entered password does not match with the confirmed password.
    */
   ERR_PASSWORD_MISMATCH: 'ERR_PASSWORD_MISMATCH',
   ERR_INTERNAL_ERROR: 'ERR_INTERNAL_ERROR'
};
